import { useState } from 'react'
import { deleteFF } from '../requests/delete-ff'
import { getAllAccountsWithFFEnabled } from '../requests/get-all-accounts-with-ff'
import '../styles/ff-list-item.css'
import { FFtoAccountView } from './ff-to-account-view'
import { Modal } from './modal'

export const FFListItem = (props: {
    name: string,
    description: string,
    onDeleteFF: () => void,
    availableAccounts: any[],
}) => {
    const { name, description, onDeleteFF, availableAccounts } = props
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
    const [isAccountsViewOpen, setAccountsViewOpen] = useState(false)
    const [connectedAccounts, setConnectedAccounts] = useState([])

    const onDeleteConfirm = () => {
        setDeleteModalOpen(false)
        deleteFF(name).then(onDeleteFF)
    }

    const onFeatureFlagClick = () => {
        getAllAccountsWithFFEnabled(name)
            .then(result => {
                setConnectedAccounts(result['content'])
                setAccountsViewOpen(true)
            })
    }

    const onFFEnablingForAccount = (ffName: string) => {
        getAllAccountsWithFFEnabled(ffName)
            .then(result => {
                setConnectedAccounts(result['content'])
            })
    }

    return (
        <div className="ff-list_item">
            <div className='ff-list_item_info' onClick={ onFeatureFlagClick }>
                <b>{ name }</b>
                { description && (
                    <span>&nbsp;(<i>{ description }</i>)</span>
                ) }
            </div>
            <Modal
                isOpen={ isAccountsViewOpen }
                handleClose={ () => setAccountsViewOpen(false) }
                content={
                    <FFtoAccountView
                        ffName={ name }
                        availableAccounts={ availableAccounts }
                        onFFEnablingForAccount={ onFFEnablingForAccount }
                        connectedAccountIds={ connectedAccounts }
                    />
                }
                closeButtonText='Cancel'
            />
            <div className="ff-list_item_delete_button">
                <button onClick={ () => setDeleteModalOpen(true) }><b>X</b></button>
                <Modal
                    isOpen={ isDeleteModalOpen }
                    content={ <p>You're about to delete Feature Flag <b>{ name }</b>. This action cannot be undone. Confirm?</p> }
                    additionalButtons={ <button onClick={ onDeleteConfirm }>Confirm🎉</button> }
                    closeButtonText={ 'Cancel' }
                    handleClose={ () => setDeleteModalOpen(false) }
                />
            </div>
        </div>
    )
}
