export const ToggleListIcon = () => 
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="none" viewBox="0 0 12 12">
            <g clipPath="url(#a)">
                <path fill="#000" fillRule="evenodd" d="M3.375 6.75a2.625 2.625 0 0 0 0 5.25h5.25a2.625 2.625 0 0 0 0-5.25h-5.25Zm5.25 4.5a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75ZM3.375.75a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75Zm1.838 0A2.618 2.618 0 0 1 6 2.625 2.617 2.617 0 0 1 5.213 4.5h3.412a1.875 1.875 0 0 0 0-3.75H5.213ZM3.375 0h5.25a2.625 2.625 0 0 1 0 5.25h-5.25a2.625 2.625 0 0 1 0-5.25Z" clipRule="evenodd" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h12v12H0z" />
                </clipPath>
            </defs>
        </svg>

