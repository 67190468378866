import { apiHost } from "./utils/api-host-resolver";
import { API_PATH, TOKEN } from "./constants";

export const createFF: (name: string, description: string) => Promise<any> =
    async (name: string, description: string) => {
        const token = localStorage.getItem(TOKEN)
        return await fetch(`${apiHost}${API_PATH}/ff`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify({ name, description })
        })
            .then(resp => resp.json())
            .catch(e => console.log(e))
    }