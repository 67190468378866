import { useState } from "react"
import { enableFFForAccount } from "../requests/enable-ff-for-account"

export const FFtoAccountView = (props: { ffName: string, availableAccounts: any[], connectedAccountIds: any[], onFFEnablingForAccount: (ffName: string) => any }) => {
    const { ffName, availableAccounts, connectedAccountIds, onFFEnablingForAccount } = props

    const defaultOption = <option key='-1' value="">--Please choose an account--</option>
    const accountOptions = availableAccounts
        .filter(account => connectedAccountIds.indexOf(account['accountId']) < 0)
        .map((candidate, index) => {
            return <option key={ index } value={ candidate['accountId'] }>
                { `${candidate['ownerEmail']} (${candidate['workspaceNames']})` }
            </option>
        })
    const allOptions = [defaultOption].concat(accountOptions)

    const [selectedAccountId, setSelectedAccountId] = useState("")

    return (
        <div>
            <p>Accounts having <b>{ ffName }</b> enabled</p>
            { connectedAccountIds
                .map(
                    connectedAccountId => availableAccounts.find(availableAccount => availableAccount['accountId'] === connectedAccountId)
                ).map((connectedAccount, index) => <div key={ index }>{ `${connectedAccount['ownerEmail']}(${connectedAccount['workspaceNames']})` }</div>)
            }

            <p>Enable Feature Flag for:</p>
            <select
                value={ selectedAccountId }
                onChange={ event => setSelectedAccountId(event.target.value) }
            >
                { allOptions }
            </select>
            <button
                disabled={ !selectedAccountId }
                onClick={ () => {
                    enableFFForAccount(ffName, selectedAccountId)
                        .then(() => {
                            setSelectedAccountId("")
                            onFFEnablingForAccount(ffName)})
                } }
            >
                Enable🔥
            </button>
        </div>
    )
}