import '../styles/modal.css'

export const Modal = (props:{ handleClose: () => void, isOpen: boolean, content: React.ReactNode, closeButtonText: string, additionalButtons?: React.ReactNode }) => {
    const { handleClose, isOpen, closeButtonText, content, additionalButtons} = props;
    const showHideClassName = isOpen ? "modal display-block" : "modal display-none";
    return (
        <div className={ showHideClassName }>
            <section className="modal-main">
                { content }
                <div className='modal_buttons'>
                    {additionalButtons}
                    <button type="button" onClick={ handleClose }>
                        {closeButtonText}
                    </button>
                </div>
            </section>
        </div>
    );
};