import { apiHost } from "./utils/api-host-resolver";
import { API_PATH, TOKEN } from "./constants";

export const deleteFF: (name: string) => Promise<any> =
    async (name: string) => {
        const token = localStorage.getItem(TOKEN)
        return await fetch(`${apiHost}${API_PATH}/ff`, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}` },
            body: name
        })
            .then(resp => resp.json())
            .catch(e => console.log(e))
    }