import { useState } from "react"
import { FFList } from "./components/ff-list"
import { Modal } from './components/modal'
const FF_TOKEN = "FF_TOKEN"

export const App = () => {
    
    const [isTokenModalOpen, setTokenModalOpen] = useState(true)
    const [token, setToken] = useState<string | undefined>()
    return <main>
        { !localStorage.getItem(FF_TOKEN) && <Modal
            isOpen={ isTokenModalOpen }
            content={
                <div>
                    <p>Set Token to Manage Feature Flags</p>
                    <input value={ token } onChange={ (event) =>setToken(event.target.value) } placeholder={ 'Token' } />
                </div>
            }
            closeButtonText={ 'Save Token' }
            handleClose={ () => {
                localStorage.setItem(FF_TOKEN, token || "")
                setTokenModalOpen(false)
            } }
        />}
        { !!localStorage.getItem(FF_TOKEN) && <FFList/> }
    </main>
}