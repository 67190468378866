import { apiHost } from "./utils/api-host-resolver"
import { API_PATH, TOKEN } from "./constants"

export const getAllFFs: () => Promise<any> =
    async() => {
        const token = localStorage.getItem(TOKEN)
        return await fetch(`${apiHost}${API_PATH}/ff`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        })
            .then(resp => resp.json())
            .catch(e => console.log(e))
    }