import { ChangeEvent, useEffect, useState } from "react";
import { MagicWandIcon } from "../icons/magic-wand-icon";
import { ToggleListIcon } from "../icons/toogle-list-icon";
import { createFF } from "../requests/create-ff";
import { getAllAvailableAccounts } from "../requests/get-all-available-accounts";
import { getAllFFs } from "../requests/get-all-ffs";
import '../styles/ff-list.css';
import { FFListItem } from "./ff-list-item";

export const FFList = () => {
    const loadFFData = () => {
        getAllFFs().then((result) => setItems(result['content']));
        getAllAvailableAccounts().then((result) => setAvailableAccounts(result['content']))
    };
    useEffect(loadFFData,[]);

    const [availableAccounts, setAvailableAccounts] = useState<any[]>([])
    const [items, setItems] = useState<{ name: string, description: string }[]>([]);
    const [ffToAddName, setNameOfFFToAdd] = useState("");
    const [ffToAddDescription, setDescriptionOfFFToAdd] = useState("");
    const onCreateFFClick = () => {
        createFF(ffToAddName, ffToAddDescription).then(loadFFData);
    };
    const onChangeNameOfFFToAdd = (newNameEvent: ChangeEvent<HTMLInputElement>) => {
        setNameOfFFToAdd(newNameEvent.target.value)
    };
    const onChangeDescriptionOfFFToAdd = (newDescriptionEvent: ChangeEvent<HTMLInputElement>) => {
        setDescriptionOfFFToAdd(newDescriptionEvent.target.value)
    };

    return (
        <div className="ff-list_container">
            <div className="ff-list_header">
                <ToggleListIcon />
                <h1>Magic Flags</h1>
                <MagicWandIcon />
            </div>
            <div className="ff-list_adder">
                <input
                    placeholder="Feature Flag name"
                    value={ ffToAddName }
                    onChange={ onChangeNameOfFFToAdd }
                />
                <input
                    placeholder="Feature Flag description"
                    value={ ffToAddDescription }
                    onChange={ onChangeDescriptionOfFFToAdd }
                />
                <button onClick={ onCreateFFClick } disabled={ !ffToAddName }>Add Feature Flag</button>
            </div>
            <div>{ items.map((item,index) =>
            //TODO: why it's called twice?!
                {
                    return <FFListItem
                        key={ index }
                        name={ item.name }
                        description={ item.description }
                        onDeleteFF={ loadFFData }
                        availableAccounts={availableAccounts}
                    />
                }
            )}
            </div>
        </div>
    );
};